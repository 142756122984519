import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/www/src/layouts/ApiLayout.js";
import { graphql } from 'gatsby';
import ComponentApi from '../../components/ComponentApi';
import ReactPlayground from '../../components/ReactPlayground';
import CarouselControlled from '../../examples/Carousel/Controlled';
import CarouselUncontrolled from '../../examples/Carousel/Uncontrolled';
import CarouselFade from '../../examples/Carousel/CarouselFade';
import IndividualIntervals from '../../examples/Carousel/IndividualIntervals';
export const query = graphql`
  query CarouselQuery {
    carousel: componentMetadata(displayName: { eq: "Carousel" }) {
      displayName
      ...ComponentApi_metadata
    }
    item: componentMetadata(displayName: { eq: "CarouselItem" }) {
      displayName
      ...ComponentApi_metadata
    }
    caption: componentMetadata(displayName: { eq: "CarouselCaption" }) {
      displayName
      ...ComponentApi_metadata
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "carousels"
    }}>{`Carousels`}</h1>
    <p className="lead">
  A slideshow component for cycling through elements—images or slides of
  text—like a carousel.
    </p>
    <h2 {...{
      "id": "example"
    }}>{`Example`}</h2>
    <p>{`Carousels don’t automatically normalize slide dimensions. As such, you
may need to use additional utilities or custom styles to appropriately
size content. While carousels support previous/next controls and
indicators, they’re not explicitly required. Add and customize as you
see fit.`}</p>
    <ReactPlayground codeText={CarouselUncontrolled} mdxType="ReactPlayground" />
    <h2 {...{
      "id": "controlled"
    }}>{`Controlled`}</h2>
    <p>{`You can also `}<em parentName="p">{`control`}</em>{` the Carousel state, via the
`}<inlineCode parentName="p">{`activeIndex`}</inlineCode>{` prop and `}<inlineCode parentName="p">{`onSelect`}</inlineCode>{` handler.`}</p>
    <ReactPlayground codeText={CarouselControlled} mdxType="ReactPlayground" />
    <h2 {...{
      "id": "crossfade"
    }}>{`Crossfade`}</h2>
    <p>{`Add the `}<inlineCode parentName="p">{`fade`}</inlineCode>{` prop to your carousel to animate slides with a fade transition instead of a slide.`}</p>
    <ReactPlayground codeText={CarouselFade} mdxType="ReactPlayground" />
    <h2 {...{
      "id": "individual-item-intervals"
    }}>{`Individual Item Intervals`}</h2>
    <p>{`You can specify individual intervals for each carousel item via the `}<inlineCode parentName="p">{`interval`}</inlineCode>{`
prop.`}</p>
    <ReactPlayground codeText={IndividualIntervals} mdxType="ReactPlayground" />
    <h2 {...{
      "id": "api"
    }}>{`API`}</h2>
    <ComponentApi metadata={props.data.carousel} mdxType="ComponentApi" />
    <ComponentApi metadata={props.data.item} exportedBy={props.data.carousel} mdxType="ComponentApi" />
    <ComponentApi metadata={props.data.caption} exportedBy={props.data.carousel} mdxType="ComponentApi" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      